@import "modules/common";



.wrapper{width: 1000px;}


@media only screen and (min-width: 1440px) {



}



@import "partials/large/all";
