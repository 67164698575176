.form{
  .form_entry{
    @include trash;
    .field{ padding-right: 40px; 
      +.field{ padding-right: 0px; padding-left: 40px; }
    }

  }



}