.share_blk{
	text-align: left;position: relative; padding: 40px; 
	.part_subtitle{
		line-height: 35px; margin-bottom: 0; 
	}
	.buttons{position: absolute; top: 40px; right: 40px; width: 610px; text-align: right; 
		.btn{width: auto; }
	}
}

.plan_chooser_ctnr{
	.plan_chooser{
		li{width: 16.6666%;}
	}
}

